import React, { useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
// import { Location } from '@reach/router'
import queryString from "query-string"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap"
import axios from "axios"
import makeId from "../helpers/makeId"

import "bootstrap/dist/css/bootstrap.min.css"

import { Formik } from "formik"
import * as Yup from "yup"

const welcomeImage = require("../images/register_join.svg")
const logoName = require("../images/gladli_name_lg.png")
let endpointBaseUrl

if (process.env.GATSBY_RUNNING_ENV === "staging") {
  endpointBaseUrl = "us-central1-gladli-staging"
} else {
  endpointBaseUrl = "us-central1-gladli"
}

// Validation for the form
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label("E-mail")
    .email("Enter a valid email")
    .required("Please enter an email"),
  companyName: Yup.string()
    .label("Company Name")
    .required("Enter your company name")
    .min(2, "Name must have at least 2 characters"),
  orgNum: Yup.string()
    .label("Org. number")
    .required("Enter your organisation number")
    .min(10, "Org number must have exactly 10 digits")
    .max(10, "Org number must have exactly 10 digits"),
  repr: Yup.string()
    .label("Name")
    .required("Enter your representatives name")
    .min(2, "Name must have at least 2 characters"),
  phone: Yup.number()
    .label("Phone number")
    .required("Enter company phone number")
    .min(10, "Number must have at least 10 digits"),
  password: Yup.string()
    .label("Password")
    .required("Enter a password")
    .min(8, "Password must have at least 8 characters "),
  repeatPass: Yup.string()
    .label("Repeat password")
    .required("Repeat the password")
    .test("passwords-match", "Passwords does not match", function (value) {
      return this.parent.password === value
    }),
})

function handleOnRegister(values, actions, setLoading, query, fee) {
  const { email, password, companyName, orgNum, repr, phone } = values
  try {
    actions.setSubmitting(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(cred => {
        // Send Confirmation email
        if (cred && cred.emailVerified === false) {
          cred
            .sendEmailVerification()
            .then(() => {
              console.log("E-mail verification sent to user")
            })
            .catch(error => {
              console.log(error)
            })
        }

        const businessId = makeId(25)

        // Create Stripe user and Link
        const payload = {
          businessType: "company",
          baseUrl: window.location.origin,
          country: "SE",
          email: email,
          companyName: companyName,
          orgNum: orgNum,
          bid: businessId,
          query: query,
        }

        axios
          .post(
            `https://${endpointBaseUrl}.cloudfunctions.net/createAccLink`,
            payload
          )
          .then(res => {
            // Databse init
            const db = firebase.firestore()

            const businessObject = {
              about: {
                content: "",
                title: "",
              },
              bid: businessId,
              cat: "",
              city: "",
              email: email,
              fee: fee,
              hours: {
                mon: {
                  close: "",
                  closed: false,
                  open: "",
                },
                tue: {
                  close: "",
                  closed: false,
                  open: "",
                },
                wed: {
                  close: "",
                  closed: false,
                  open: "",
                },
                thu: {
                  close: "",
                  closed: false,
                  open: "",
                },
                fri: {
                  close: "",
                  closed: false,
                  open: "",
                },
                sat: {
                  close: "",
                  closed: false,
                  open: "",
                },
                sun: {
                  close: "",
                  closed: false,
                  open: "",
                },
              },
              image:
                "https://firebasestorage.googleapis.com/v0/b/gladli.appspot.com/o/restaurants%2FSk%C3%A4rmklipp.JPG?alt=media&token=efdb8c00-8a60-4c24-bc60-e49eeaefc4cd",
              locations: [],
              logo:
                "https://firebasestorage.googleapis.com/v0/b/gladli.appspot.com/o/restaurants%2FSk%C3%A4rmklipp.JPG?alt=media&token=efdb8c00-8a60-4c24-bc60-e49eeaefc4cd",
              multisite: false,
              online: false,
              org_nr: "",
              phone: phone,
              postal: "",
              price: 0,
              rating: 0,
              repr: repr,
              stats_id: "",
              street: "",
              stripe: {
                account: {
                  id: res.data.id,
                  complete: false,
                },
              },
              title: companyName,
              values: [150, 300, 600],
            }

            // Update user document wih form info
            db.collection("businesses")
              .doc(businessId)
              .set(businessObject, { merge: true })
              .then(() => {
                console.log(res.data)

                db.collection("business_users")
                  .doc(cred.user.uid)
                  .set({
                    admin: true,
                    bid: businessId,
                    email: email,
                    fname: repr.split(" ", 1)[0],
                    lname: repr.split(" ", 1)[1],
                    location: "",
                    uid: cred.user.uid,
                  })
                  .then(() => {
                    actions.setSubmitting(false)
                    actions.resetForm()
                    setLoading(false)
                    window.location.href = res.data.link.url
                  })
                  .catch(error => {
                    console.error(
                      "Error writing business user document: ",
                      error
                    )
                  })
              })
              .catch(error => {
                console.error("Error writing business document: ", error)
              })
          })
      })
      .catch(error => {
        if (error.code === "auth/email-already-in-use") {
          actions.setSubmitting(false)
          actions.resetForm()
          setLoading(false)
        }
      })
  } catch (error) {
    actions.setFieldError("general", error.message)
    actions.setSubmitting(false)
    setLoading(false)
  }
}

function ErrModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.content.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.content.title}</h5>
        <p>{props.content.text}</p>
        {props.content.success ? (
          <p>
            <a href="https://dashboard.gladli.se/">Gladli Dashboard</a>
          </p>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="modalButton"
          onClick={() => {
            window.location.href = "https://www.gladli.se/"
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function IndexPage({ location }) {
  const [initialVal, setInitialVal] = useState({
    email: "",
    companyName: "",
    repr: "",
    phone: "",
    password: "",
    repeatPass: "",
  })
  const [loading, setLoading] = useState(false)
  const [fee, setFee] = useState(0)
  const [modalContent, setModalContent] = useState({
    heading: "",
    title: "",
    text: "",
  })
  const [modalShow, setModalShow] = useState(false)
  const [regValid, setRegValid] = useState(false)

  const reg = queryString.parse(location.search).reg
  const success = queryString.parse(location.search).success

  useEffect(() => {
    if (reg !== undefined && success === undefined) {
      setLoading(true)
      axios
        .post(`https://${endpointBaseUrl}.cloudfunctions.net/checkRegId`, {
          data: reg,
        })
        .then(res => {
          if (!("error" in res.data)) {
            setRegValid(true)
            // Set initial values in registration form
            const { email, business, phone, repr, fee } = res.data
            setFee(fee)
            setInitialVal({
              email: email,
              companyName: business,
              repr: repr,
              phone: phone,
              password: "",
              repeatPass: "",
            })
          } else if (res.data.error === "No reg ID match.") {
            // console.log('error', res.data)
            setModalContent({
              heading: "Invalid URL",
              title: "Something went wrong...",
              text:
                "You seem to have used a link that isn't valid. This might be because you have already signed up or the link you entered is misspelled. If the problem persists, please contact Gladli support at support@gladli.se and we will be happy to help you out!",
            })
            setModalShow(true)
          }
          setLoading(false)
        })
    } else if (success === "true") {
      setModalContent({
        heading: "Congratulations!",
        title: "You have now created a Gladli Account",
        text:
          "Now the only thing left to do is to log into the Gladli Dashboard by following the link below:",
        success: true,
      })
      setModalShow(true)
    } else if (success === "almost") {
      setModalContent({
        heading: "Almost Done!",
        title: "You have created a Gladli Account! But...",
        text:
          "You will not be able to make your store/restaurant visible until you have provided the required information in the business validation form. In the mean time you can download the application from here:",
        success: true,
      })
      setModalShow(true)
    } else {
      setModalContent({
        heading: "Invalid URL",
        title: "Something went wrong...",
        text:
          "You seem to have used a link that isn't valid. This might be because you have already signed up or the link you entered is misspelled. If the problem persists, please contact Gladli support at support@gladli.se and we will be happy to help you out!",
      })
      setModalShow(true)
    }

    return undefined
  }, [reg, success])

  return (
    <Container fluid id="regContainer">
      <ErrModal
        content={modalContent}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Row>
        <Col id="regLeftCol">
          <div id="regLeftContainer">
            <div id="imgContainer">
              <img id="logoName" src={logoName} alt="" />
              <img
                id="welcomeTopImg"
                src={welcomeImage}
                alt="Welcome to registration screen"
              />
            </div>
            <h1>WELCOME!</h1>
            <p>
              We're so happy that you are here! Because that means that you are
              trusting us to help you increase your sales and at the same time
              make the world a better place! Our mission at Gladli is to help
              you reach more potential customers and to spread hapiness wherever
              we can. Since you shouldn't have to spend valuable time managing
              details such as gift cards we can take that load off your
              shoulders and enable you to do what you do best, run your
              business.
            </p>
          </div>
        </Col>
        <Col id="regRightCol">
          {!loading && (
            <div id="regCardContainer">
              <div>
                <p>Step 1/2: Sign up for a Gladli Account</p>
                <h2>Let's Get Started!</h2>
              </div>
              <Formik
                initialValues={initialVal}
                enableReinitialize
                onSubmit={(values, actions) => {
                  setLoading(true)
                  handleOnRegister(
                    values,
                    actions,
                    setLoading,
                    queryString.parse(location.search).bid,
                    fee
                  )
                }}
                validationSchema={validationSchema}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  isValid,
                  errors,
                  touched,
                  handleBlur,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="formGroup" controlId="formEmail">
                      <Form.Label className="formLabel">E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter an E-mail address to be used as account name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.email}
                        className={
                          touched.email && errors.email ? "error" : null
                        }
                      />
                      {touched.email && errors.email ? (
                        <div className="error-message">{errors.email}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="formCoName">
                      <Form.Label className="formLabel">
                        Company Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        placeholder="Enter your Company Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.companyName}
                        className={
                          touched.companyName && errors.companyName
                            ? "error"
                            : null
                        }
                      />
                      {touched.companyName && errors.companyName ? (
                        <div className="error-message">
                          {errors.companyName}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="formOrgNum">
                      <Form.Label className="formLabel">Org. Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="orgNum"
                        placeholder="Enter your Organisation number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.orgNum}
                        className={
                          touched.orgNum && errors.orgNum ? "error" : null
                        }
                      />
                      {touched.orgNum && errors.orgNum ? (
                        <div className="error-message">{errors.orgNum}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="formRep">
                      <Form.Label className="formLabel">
                        Representative
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="repr"
                        placeholder="Enter full name of the Legal Representative"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.repr}
                        className={touched.repr && errors.repr ? "error" : null}
                      />
                      {touched.repr && errors.repr ? (
                        <div className="error-message">{errors.repr}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="formPhone">
                      <Form.Label className="formLabel">Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Enter representatives phone number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.phone}
                        className={
                          touched.phone && errors.phone ? "error" : null
                        }
                      />
                      {touched.phone && errors.phone ? (
                        <div className="error-message">{errors.phone}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="formPass">
                      <Form.Label className="formLabel">Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter a password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.password}
                        className={
                          touched.password && errors.password ? "error" : null
                        }
                      />
                      {touched.password && errors.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group
                      className="formGroup"
                      controlId="formRepeatPass"
                    >
                      <Form.Label className="formLabel">Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="repeatPass"
                        placeholder="Repeat your password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!regValid}
                        value={values.repeatPass}
                        className={
                          touched.repeatPass && errors.repeatPass
                            ? "error"
                            : null
                        }
                      />
                      {touched.repeatPass && errors.repeatPass ? (
                        <div className="error-message">{errors.repeatPass}</div>
                      ) : null}
                    </Form.Group>

                    <Button
                      id="regButton"
                      variant="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting || !regValid}
                    >
                      {!loading ? (
                        "Next Step"
                      ) : (
                        <Spinner animation="border" size="sm" variant="light" />
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
